import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";

// Layouts
import Header from "../../layout/header/index";
import Content from "../../layout/content/index";

// Components
import Table from "../../components/tables/index";
import Loading from "../../components/loading/index";
import Error from "../../components/error/index";

// Styles
import "../../assets/css/App.css";

export default function Index() {
  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showError, setShowError] = useState(false);
  const defaultStartTime = moment().set({ hour: 3, minute: 0 });
  const defaultEndTIme = moment().set({ hour: 23, minute: 0 });

  useEffect(() => {
    const fetchData = async () => {

      setShowError(false);

      try {
        const { data: response } = await axios.get(
          `${
            process.env.REACT_APP_API_LOCAL_URL
          }/api/portreq?fromDate=${defaultStartTime.toISOString()}&toDate=${defaultEndTIme.toISOString()}`
        );

        if (response[0].data[0].length > 1) {
          setData(response[0].data[0]);
        } else {
          setData(response[0].data);
        }

        setShowTable(true);

      } catch (error) {
        setShowTable(false);
        console.log(error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
      setShowTable(false);
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);


  }, []);

  return (
    <div className="wrapper">
      <Content>
        {showTable === true && (
          <Table data={data} columnTitles={"SHIP NAME,ETA,ETD, LOCATION"} />
        )}
        {showTable === false && showError === false && <Loading />}
        
        {showError === true &&
            <Error />
        }
      </Content>
    </div>
  );
}
