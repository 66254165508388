import moment from 'moment'
import { ClockIcon, CalendarDaysIcon } from '@heroicons/react/24/solid'

export default function Table(props) {
  const sortData = props.data.sort((a,b)=>new Date(a.k.VoyageInformation.ArrivalETA.text) - new Date(b.k.VoyageInformation.ArrivalETA.text));
  const dataItems = sortData.map((item, key) => (
    <tr key={key}>
      <td className="px-5 py-2 bg-transparent text-sm">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <img
              alt={item.k.ShipInformation.ShipIdentification.ShipName.text}
              src="https://skipstrafikk.moss-havn.no/static/media/ship.f42acb07d8eacadd7affd80fb45f3610.svg"
              className="mx-auto object-cover rounded-full h-6 w-6 lg:h-10 lg:w-10"
            />
          </div>
          <div className="ml-3">
            <p className="text-gray-900 whitespace-no-wrap text-xs lg:text-sm">
              {item.k.ShipInformation.ShipIdentification.ShipName.text}
            </p>
          </div>
        </div>
      </td>
      <td className="px-5 py-2 bg-transparent text-sm">
        <p className="text-gray-900 whitespace-no-wrap text-xs lg:text-sm">
          <div className="flex"><CalendarDaysIcon className="h-5 w-5 text-black" /> <p className="ml-2 text-black">{moment(item.k.VoyageInformation.ArrivalETA.text).format("DD.MM.YYYY")}</p></div>
        </p>
      </td>
      <td className="px-5 py-2 bg-transparent text-sm">
        <p className="text-gray-900 whitespace-no-wrap text-transparent lg:text-sm">
          <div className="flex"><ClockIcon className="h-5 w-5 text-black" /> <p className="ml-2 text-black">kl {moment(item.k.VoyageInformation.ArrivalETA.text).format("HH:mm")}</p></div>
        </p>
      </td>
    </tr>
  ));

  return (
    <div className="mx-auto">
      <div className="py-0">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <tbody>{dataItems}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
